@mixin flex($direction: row, $horizontal: space-between, $vertical: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $vertical;
  justify-content: $horizontal;
}
@mixin backgroundImg($w: 0, $h: 0, $url: '', $size: contain) {
  display: inline-block;
  width: $w;
  height: $h;
  background: url($url) no-repeat center;
  background-size: $size;
}

@mixin ellipsis($line: 1) {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}