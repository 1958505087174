@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.contact-container{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  .main{
    @include flex(column, flex-start, center);
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .title{
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-top: 50px;
      height: 48px;
      line-height: 48px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
    }
    .desc{
      margin-top: 58px;
      height: 80px;
      line-height: 40px;
      font-size: $font36;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
    }
    .contact-way{
      margin-top: 59px;
      @include flex(row, center, center);
      .mail-box{
        @include flex(column, flex-start, flex-start);
        :first-child{
          height: 20px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor333;
          line-height: 20px;
        }
        :last-child{
          &:hover{
            text-decoration: underline;
          }
          text-decoration: none;
          margin-top: 23px;
          height: 27px;
          font-size: $font26;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 400;
          color: $fontColor447;
          line-height: 27px;
        }
      }
    }
    .contact-now{
      margin-top: 60px;
      @include flex(row, center, center);
      margin-bottom: 213px;
    }
  }
}