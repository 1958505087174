@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.header-contanier{
  // overflow: hidden;
  position: relative;
  .app-header{
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $bgColorFFF;
    span{
      margin: 0;
      padding: 0;
    }
    .top-header{
      @include flex(row, flex-end, center);
      background: $bgColorF5F;
      .top-content{
        width: 1248px;
        margin-left: auto;
        margin-right: auto;
        @include flex(row, flex-end, center);
        .font-box{
          margin-right: 40px;
          @include flex(row, center, flex-start);
          color: $fontColor1E4;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          .font-small{
            cursor: pointer;
            @include flex(column, flex-start, center);
            p{
              margin-top: 3px;
              height: 12px;
              line-height: 12px;
              font-size: 16px;
            }
          }
          .font-default{
            cursor: pointer;
            margin: 0 16px;
            @include flex(column, flex-start, center);
            p{
              margin-top:  1px;
              line-height: 14px;
              font-size: 18px;
            }
          }
          .font-big{
            cursor: pointer;
            @include flex(column, flex-start, center);
            p{
              height: 15px;
              line-height: 15px;
              font-size: 20px;
            }
          }
          span{
            width: 12px;
            margin-top: 2px;
            height: 2px;
            background-color: $bgColor1E4;
          }
        }
        .contact-us-btn{
          cursor: pointer;
          margin-left: 47px;
          text-align: center;
          width: 120px;
          height: 46px;
          line-height: 46px;
          background: $bgColor447;
          font-size: $font18;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColorFFF;
        }
        .contact-box{
          @include flex(row, flex-end, center);
          // width: 1248px;
          height: 46px;
          // margin-left: auto;
          // margin-right: auto;
          font-size: $font14;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor6F6;
          .customer-support{
            cursor: pointer;
            height: 14px;
            line-height: 14px;
            margin-right: 41px;
          }
          .line{
            width: 2px;
            height: 24px;
            background: $bgColorC8C;
            margin-right: 41px;
          }
          .contact-us{
            cursor: pointer;
            height: 14px;
            line-height: 14px;
            margin-right: 40px;
          }
          .login-btn{
            cursor: pointer;
            width: 120px;
            height: 46px;
            background: $fontColor1E4;
            text-align: center;
            line-height: 46px;
            color: $fontColorFFF;
          }
        }
      }
    }
    .nav-bar{
      @include flex(row, flex-start, center);
      background-color: $bgColorFFF;
      .nav-bar-container{
        @include flex(row, space-between, center);
        width: 1248px;
        height: 76px;
        margin-left: auto;
        margin-right: auto;
        .logo-box{cursor: pointer;
          @include flex();
          justify-content: flex-start;
          img{
            width: 104px;
            height: 48px;
          }
        }
        .action-box{
          @include flex(row, flex-end, center);
          .menu-list{
            @include flex(row, flex-end, center);
            .menu-item{
              cursor: pointer;
              height: 14px;
              font-size: $font14;
              font-family: $fontFamilySourceHanSansCNMedium;
              font-weight: 500;
              color: $fontColor1E4;
              line-height: 14px;
              margin-right: 32px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
          .search-box{
            margin-left: 32px;
            cursor: pointer;
            @include flex(row, center, center);
            width: 136px;
            height: 46px;
            border: 2px solid #1E4994;
            img{
              width: 16px;
              height: 16px;
              margin-right: 5px;
              object-fit: cover;
            }
            span{
              height: 14px;
              font-size: $font14;
              font-family: $fontFamilySourceHanSansCNMedium;
              font-weight: 500;
              color: $fontColor1E4;
              line-height: 14px;
            }
          }
        }
      }
      
      .right-box{
        @include flex();
        justify-content: flex-start;
        .menu-box{
          // margin-right: 60px;
          ul{
            border: none;
          }
          .menu-item{
            font-size: $font16;
            font-weight: 400;
            color: $fontColor303;
          }
        }
        .switch-language{
          margin-right: 24px;
          width: 22px;
          height: 22px;
        }
        .search-icon{
          cursor: pointer;
          height: 22px;
          font-size: $font16;
          font-weight: 400;
          color: $fontColor303;
          line-height: 22px;
        }
      }
    }
  }
  .search-nav{
    @include flex(row,  center, center);
    position: fixed;
    z-index: 1001;
    top: 122px;
    left: 0;
    width: 100%;
    height: 0px;
    transition: all 0.4s;
    background: $bgColorFFF;
    overflow: hidden;
    &.search-nav-animate{
      height: 78px;
      transition: height 0.4s;
    }
    .input-box{
      width: 772px;
      height: 46px;
      padding: 0 24px;
      box-sizing: border-box;
      border: 2px solid #D3D3D3;
      @include flex(row,  flex-start, center);
      img{
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      input{
        flex: 1;
        border-radius: 0;
        border: none;
        background: transparent !important;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        height: 46px;
        color: $fontColor333;
        &::-webkit-input-placeholder{
          color: $fontColor9D9;
          
        }
        &::-moz-placeholder{   /* Mozilla Firefox 19+ */
          color: $fontColor9D9;
        }
      }
    }
    span{
      cursor: pointer;
      margin-left: 8px;
      width: 120px;
      height: 46px;
      background: $bgColor1E4;
      text-align: center;
      line-height: 46px;
      font-size: $font14;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
    }
  }
  .search-container{
    @include flex(column, flex-start, flex-start);
    position: fixed;
    z-index: 999;
    top: 122px;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

// .ant-menu-overflow{
//   border: none !important;
// }