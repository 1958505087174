@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.product-item{
  p{
    margin: 0;
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  width: 394px;
  margin-right: 32px;
  &:last-child{
    margin-right: 0px;
  }
  .cover{
    width: 394px;
    height: 220px;
  }
  .title{
    @include ellipsis(2);
    margin: 30px 1px 0;
    height: 58px;
    font-size: $font26;
    font-family: $fontFamilySourceHanSansCNMedium;
    font-weight: 500;
    color: $fontColor333;
    line-height: 29px;
  }
  .desc{
    @include ellipsis(3);
    margin: 32px 2px 0;
    height: 90px;
    font-size: $font20;
    font-family: $fontFamilySourceHanSansCNRegular;
    font-weight: 400;
    color: $fontColor333;
    line-height: 30px;
  }
  .bottom{
    @include flex(row, flex-start, center);
    margin-top: 35px;
    p{
      height: 16px;
      font-size: $font16;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor1E4;
      line-height: 16px;
    }
    img{
      margin-left: 4px;
      width: 15px;
      height: 9px;
    }
  }
}