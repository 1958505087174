@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';

.search-content-bg{
  margin-top: 78px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: $bgColorFFF;
  @include flex(column,  flex-start, center);
  .search-content{
    width: 900px;
    padding-bottom: 60px;
    .filter-title{
      margin-top: 8px;
      @include flex(row,  flex-start, center);
      p{
        margin-right: 6px;
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
      img{
        width: 7px;
        height: 11px;
      }
    }
    .filter-box{
      margin-top: 15px;
      @include flex(column,  flex-start, flex-start);
      border: 1px solid #D3D3D3;
      padding: 0 16px;
      box-sizing: border-box;
      .insurance-species{
        @include flex(row,  flex-start, flex);
        line-height: 48px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        width: 100%;
        p{
          width: 80px;
          height: 48px;
          margin-left: 7px;
          margin-right: 40px;
          color: $fontColor9D9;
        }
        .species-list{
          flex: 1;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          .species-menu{
            cursor: pointer;
            margin-right: 40px;
            color: $fontColor333;
            &:last-child{
              margin-right: 0px;
            }
            &.species-menu-active{
              color: $fontColor447;
            }
          }
        }
      }
    }
    .sort-box{
      @include flex(row,  flex-start, center);
      width: 100%;
      margin-top: 16px;
      background: $bgColorFAF;
      height: 48px;
      border: 1px solid #D3D3D3;
      .default-sort{
        @include flex(row, center, center);
        cursor: pointer;
        padding: 0 24px;
        height: 48px;
        box-sizing: border-box;
        color: $fontColor9D9;
        &:hover{
          color: $fontColor447;
          border: 1px solid #447EDA;
        }
        &.default-sort-selected{
          color: $fontColorFFF;
          background-color: $bgColor447;
        }
        span{
          height: 16px;
          line-height: 16px;
          font-size: $fontFamilySourceHanSansCNMedium;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        img{
          // width: 12px;
          // height: 8px;
          margin-left: 4px;
        }
      }
    }
    .search-result-title{
      margin-top: 23px;
      height: 20px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 400;
      color: $fontColor333;
      line-height: 20px;
    }
    .search-result{
      margin-top: 23px;
      @include flex(column,  flex-start, flex-start);
      .search-result-item{
        width: 100%;
        @include flex(column,  flex-start, flex-start);
        border: 1px solid #D3D3D3;
        margin-top: 16px;
        &:hover{
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.32);
        }
        &:first-child{
          margin-top: 0;
        }
        .top{
          @include flex(row, space-between, flex-start);
          padding: 22px 24px 0;
          box-sizing: border-box;
          width: 100%;
          .left{
            flex: 1;
            @include flex(row, flex-start, flex-start);
            img{
              margin-top: 2px;
              width: 127px;
              height: 77px;
              margin-right: 16px;
            }
            p{
              @include ellipsis(2);
              line-height: 22px;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
            }
          }
          .price{
            margin-left: 50px;
            margin-top: 28px;
            height: 26px;
            line-height: 26px;
            font-size: $font26;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor447;
          }
        }
        .tag-box{
          width: 100%;
          padding: 16px 24px 0;
          box-sizing: border-box;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          span{
            text-align: center;
            border: 1px solid #F2A915;
            padding: 7px 8px;
            box-sizing: border-box;
            font-size:$font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColorF2A;
            line-height: 19px;
            margin-right: 8px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .bottom{
          width: 100%;
          margin-top: 16px;
          @include flex(row, space-between, flex-start);
          background: $bgColorFFFA;
          padding: 24px 24px 36px;
          .left{
            width: 60%;
            @include flex(column, flex-start, flex-start);
            .desc-box{
              width: 100%;
              @include flex(row, space-between, center);
              margin-top: 22px;
              &:first-child{
                margin-top: 0px;
              }
              :first-child{
                max-width: 60%;
              }
              :last-child{
                text-align: right;
                max-width: 38%
              }
              p{
                @include ellipsis(1);
                height: 16px;
                line-height: 16px;
                font-size: $font16;
                font-family: $fontFamilySourceHanSansCNRegular;
                font-weight: 400;
                color: $fontColor333;
              }
            }
          }
          .check-detail{
            cursor: pointer;
            width: 120px;
            height: 46px;
            line-height: 46px;
            background: $fontColorF2A;
            text-align: center;
            font-size: $font14;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColorFFF;
          }
        }
      }
    }
  }
}