.notfound-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1248px;
  color: #6A8082;
  .title{
    margin-top: 200px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
  }
  .sub-title{
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
  }
}
