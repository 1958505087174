@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.customer-support-container{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  .nav-bar{
    background-color: $bgColorF0F;
    .nav-back{
      width: 1248px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      padding-top: 17px;
      box-sizing: border-box;
      font-size: $font16;
      font-family: $fontFamilyPingFangRegular;
      font-weight: 400;
      color: $fontColor303;
      line-height: 22px;
      :first-child{
        cursor: pointer;
      }
      :last-child{
        color: $fontColor2C7;
      }
      span{
        height: 22px;
      }
      img{
        margin: 4px 8px 0;
        width: 18px;
        height: 18px;
      }
    }
  }
  .main{
    @include flex(column, flex-start, center);
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .title{
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-top: 50px;
      height: 48px;
      line-height: 48px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
    }
    .desc{
      margin-top: 58px;
      width: 1010px;
      height: 80px;
      line-height: 40px;
      font-size: $font36;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
    }
    .support-list{
      margin-top: 61px;
      @include flex(row, center, center);
      .support-item{
        cursor: pointer;
        @include flex(row, flex-start, center);
        margin-right: 16px;
        &:last-child{
          margin-right: 0;
        }
        &:hover{
          color: $fontColor447;
          border: 2px solid #447EDA;
        }
        width: 230px;
        height: 66px;
        border: 1px solid #D3D3D3;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        img{
          margin-left: 25px;
          margin-right: 24px;
        }
        p{
          height: 20px;
          line-height: 20px;
        }
        .preservation{
          width: 42px;
          height: 42px;
        }
        .claims{
          width: 38px;
          height: 38px;
        }
        .help-center{
          width: 40px;
          height: 36px;
        }
      }
    }
    .contact-us{
      cursor: pointer;
      margin-top: 76px;
      width: 148px;
      height: 46px;
      background: $bgColor447;
      text-align: center;
      line-height: 46px;
      font-size: $font14;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
      margin-bottom: 164px;
    }
  }
}