@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.insurance-comparison{
  // margin-top: 60px;
  position: relative;
  .image-box{
    text-align: center;
    img {
      width: 100%;
      height: 460px;
      object-fit: cover;
    }
  }
  .comparison-box{
    @include flex(column, flex-start, flex-start);
    position: absolute;
    z-index: 11;
    width: 1248px;
    height: 460px;
    top: 0;
    left: calc(50vw - 624px);
    .comparison-title{
      margin-top: 135px;
      height: 49px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColorFFF;
      line-height: 49px;
    }
    .comparison-desc{
      margin-top: 24px;
      height: 20px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
      line-height: 20px;
    }
    .comparison-btn{
      cursor: pointer;
      text-align: center;
      margin-top: 41px;
      width: 210px;
      height: 54px;
      background: $bgColor447;
      font-size: $font16;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
      color: $fontColorFFF;
      line-height: 54px; 
    }
    .policy-escrow-title{
      margin-top: 135px;
      height: 49px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColorFFF;
      line-height: 49px;
    }
    .upload-btn{
      margin-top: 85px;
      cursor: pointer;
      text-align: center;
      margin-top: 41px;
      width: 210px;
      height: 54px;
      background: $bgColor447;
      font-size: $font16;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
      color: $fontColorFFF;
      line-height: 54px; 
    }
  }
}