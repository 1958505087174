@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.compare-product{
  @include flex(column, flex-start, flex-start);
  .main{
    @include flex(column, flex-start, flex-start);
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    .filter-title{
      margin-top: 8px;
      @include flex(row,  flex-start, center);
      p{
        margin-right: 6px;
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
      img{
        width: 7px;
        height: 11px;
      }
    }
    .filter-box{
      width: 100%;
      margin-top: 15px;
      @include flex(column,  flex-start, flex-start);
      border: 1px solid #D3D3D3;
      padding: 4px 22px;
      box-sizing: border-box;
      .select-insurance-company{
        width: 100%;
        @include flex(column,  flex-start, flex-start);
        border-bottom: 1px solid #D3D3D3;
        .company-box{
          @include flex(row,  flex-start, flex);
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          padding-bottom: 4px;
          width: 100%;
          p{
            margin-top: 12px;
            width: 80px;
            height: 16px;
            margin-left: 7px;
            margin-right: 40px;
            color: $fontColor9D9;
          }
          .species-list{
            flex: 1;
            @include flex(row,  flex-start, center);
            flex-wrap: wrap;
            .species-menu-box{
              cursor: pointer;
              @include flex(row,  center, center);
              margin: 12px 40px 12px 0;
              &:last-child{
                margin-right: 0px;
              }
              img{
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
              .species-menu{
                user-select: none;
                cursor: pointer;
                height: 16px;
                color: $fontColor333;
                &.species-menu-active{
                  color: $fontColor447;
                }
              }
            }
          }
          .more-btn{
            cursor: pointer;
            margin-top: 12px;
            width: 44px;
            height: 20px;
            border: 1px solid #447EDA;
            line-height: 20px;
            text-align: center;
            font-size: $font14;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor447;
          }
        }
        .select-box{
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          @include flex(row, center, center);
          line-height: 28px;
          font-size: $font14;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColorFFF;
          span{
            cursor: pointer;
            text-align: center;
            background: $bgColor447;
            width: 44px;
            height: 28px;
          }
          :last-child{
            margin-left: 32px;
            color: $fontColor333;
            border: 1px solid #9D9D9D;
            background: $bgColorFFF;
          }
        }
      }
      .currency-box{
        @include flex(row,  flex-start, flex);
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        padding-bottom: 4px;
        width: 100%;
        p{
          margin-top: 12px;
          width: 80px;
          height: 16px;
          margin-left: 7px;
          margin-right: 40px;
          color: $fontColor9D9;
        }
        .currency-list{
          flex: 1;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          .currency-menu-box{
            margin: 12px 40px 12px 0;
            &:last-child{
              margin-right: 0px;
            }
            .currency-menu{
              user-select: none;
              cursor: pointer;
              height: 16px;
              color: $fontColor333;
              &.currency-menu-active{
                color: $fontColor447;
              }
            }
          }
        }
      }
    }
    .sort-box{
      @include flex(row,  flex-start, center);
      width: 100%;
      margin-top: 16px;
      background: $bgColorFAF;
      height: 48px;
      border: 1px solid #D3D3D3;
      span{
        user-select: none;
        cursor: pointer;
        height: 48px;
        line-height: 48px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor9D9;
        padding: 0 24px;
        box-sizing: border-box;
        &.sort-active{
          color: $fontColorFFF;
          background-color: $bgColor447;
        }
      }
    }
    .product-box{
      @include flex(column, flex-start, flex-start);
      width: 900px;
      margin-top: 8px;
      margin-bottom: 60px;
      .product-list{
        width: 100%;
        @include flex(column, flex-start, flex-start);
        .product-item{
          cursor: pointer;
          width: 100%;
          margin-top: 16px;
          background: $bgColorFFF;
          border: 1px solid #D3D3D3;
          padding: 24px 16px;
          box-sizing: border-box;
          &:hover{
            box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.32);
          }
          .top{
            @include flex(row, space-between, center);
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor333;
            padding: 0 8px;
            box-sizing: border-box;
            .name{
              @include ellipsis();
              width: 80%;
              height: 20px;
              line-height: 20px;
            }
            .compare-box{
              cursor: pointer;
              @include flex(row, flex-start, center);
              img{
                width: 22px;
                height: 22px;
              }
              p{
                margin-left: 7px;
                height: 20px;
                line-height: 20px;
              }
            }
          }
          .product-info{
            width: 100%;
            margin-top: 30px;
            @include flex(row, space-between, flex-start);
            padding: 0 8px;
            box-sizing: border-box;
            .product-detail{
              flex: 1;
              @include flex(row, flex-start, flex-start);
              img{
                width: 127px;
                height: 77px;
              }
              p{
                @include ellipsis(2);
                line-height: 22px;
                margin-left: 16px;
                font-size: $font20;
                font-family: $fontFamilySourceHanSansCNRegular;
                font-weight: 400;
                color: $fontColor333;
              }
            }
            .product-price{
              margin-left: 50px;
              margin-top: 28px;
              height: 26px;
              line-height: 26px;
              font-size: $font26;
              font-family: $fontFamilySourceHanSansCNMedium;
              font-weight: 500;
              color: $fontColor447;
            }
          }
          .tag-box{
            width: 100%;
            padding: 16px 8px 8px;
            box-sizing: border-box;
            @include flex(row,  flex-start, center);
            flex-wrap: wrap;
            border-bottom: 1px solid #D3D3D3;
            span{
              text-align: center;
              border: 1px solid #F2A915;
              padding: 7px 8px;
              box-sizing: border-box;
              font-size:$font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColorF2A;
              line-height: 19px;
              margin-right: 8px;
              margin-bottom: 16px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
          .bottom{
            margin-top: 24px;
            @include flex(row, space-between, flex-start);
            padding: 0 8px 0 17px;
            box-sizing: border-box;
            .insured-amount{
              @include flex(row, flex-start, center);
              .insured-box{
                @include flex(column, center, center);
                font-family: $fontFamilySourceHanSansCNMedium;
                font-weight: 400;
                &:first-child{
                  margin-right: 76px;
                }
                :first-child{
                  height: 17px;
                  line-height: 17px;
                  font-size: $font20;
                  color: $fontColor447;
                }
                :last-child{
                  margin-top: 14px;
                  height: 16px;
                  line-height: 16px;
                  font-size: $font16;
                  color: $fontColor333;
                }
              }
            }
            .check-detail{
              cursor: pointer;
              margin-top: 3px;
              @include flex(row, flex-end, center);
              span{
                margin-right: 4px;
                height: 16px;
                line-height: 16px;
                font-size: $font16;
                font-family: $fontFamilySourceHanSansCNMedium;
                font-weight: 500;
                color: $fontColor1E4;
              }
            }
          }
        }
      }
    }
  }
  .compareing-product{
    position: fixed;
    width: 100%;
    height: 182px;;
    bottom: 0;
    background: $bgColor1E4;
    z-index: 1000;
    @include flex(row, center, center);
    .product-list{
      @include flex(row, center, center);
      .product-item{
        width: 230px;
        height: 134px;
        padding: 16px;
        box-sizing: border-box;
        margin-right: 24px;
        background-color: $bgColorFFF;
        &:last-child{
          margin-right: 0;
        }
        .select-item{
          width: 100%;
          height: 100%;
          @include flex(row, center, flex-start);
          .product-info{
            height: 100%;
            @include flex(row, flex-start, center);
            .product-cover{
              width: 40px;
              height: 24px;
            }
            p{
              @include ellipsis(6);
              width: 128px;
              margin-left: 8px;
              margin-right: 10px;
              line-height: 16px;
              font-size: $font16;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
            }
          }
          .btn-close{
            cursor: pointer;
            width: 12px;
            height: 12px;
          }
        }
        .normal-item{
          @include flex(row, center, center);
          width: 100%;
          height: 100%;
        }
      }
    }
    .now-compare{
      width: 230px;
      height: 134px;
      margin-left: 24px;
      @include flex(column, flex-start, center);
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      .compare-btn{
        cursor: pointer;
        width: 100%;
        height: 50px;
        background: $bgColorFFF;
        @include flex(row, center, center);
        img{
          width: 20px;
          height: 20px;
          margin-left: 8px;
          &.compare-animate{
            animation: turn 1s linear infinite;
          }
          &.compare-normal{
            display: none;
          }
        }
        span{
          height: 20px;
          line-height: 20px;
          color: $fontColor1E4;
        }
      }
      .reset-btn{
        cursor: pointer;
        margin-top: 32px;
        height: 19px;
        line-height: 19px;
        color: $fontColorFFF;
      }
    }
  }
}
.modal-dialog{
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  @include flex(column,center, center);
  .modal-content{
    width: 1246px;
    background: $bgColorFFF;
    padding: 17px 16px 28px;
    box-sizing: border-box;
    position: relative;
    .modal-header{
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 25px;
      width: 16px;
      height: 16px;
    }
    .modal-body{
      display: flex;
      flex-direction: column;
      .container-product{
        @include flex(row, flex-start, center);
        padding: 24px 0;
        box-sizing: border-box;
        // border-bottom: 1px solid #D3D3D3;
        .product-type{
          cursor: pointer;
          @include flex(column, center, center);
          width: 230px;
          height: 134px;
          background: $bgColorFFF;
          text-align: center;
          font-size: $font20;
          line-height: 22px;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
        }
        .product-list{
          @include flex(row, center, center);
          .product-item{           display: flex;
            flex-direction: column;
            width: 230px;
            height: 134px;
            margin-left: 8px;
            .select-item{
              .close-btn{
                cursor: pointer;
                margin-top: 16px;
                margin-right: 16px;
                height: 12px;
                text-align: right;
              }
              .name{
                @include ellipsis(2);
                height: 44px;
                text-align: center;
                margin-top: 8px;
                line-height: 22px;
                font-size: $font20;
                font-family: $fontFamilySourceHanSansCNRegular;
                font-weight: 400;
                color: $fontColor333;
              }
              .check-btn{
                cursor: pointer;
                display: inline-block;
                margin-top: 8px;
                width: 100%;
                height: 46px;
                background: $bgColor447;
                line-height: 46px;
                text-align: center;
                font-size: $font14;
                font-family: $fontFamilySourceHanSansCNMedium;
                font-weight: 500;
                color: $fontColorFFF;
              }
            }
            .normal-item{
              cursor: pointer;
              @include flex(row, center, center);
              width: 100%;
              height: 100%;
              span{
                width: 120px;
                border: 2px solid #1E4994;
                line-height: 46px;
                text-align: center;
                font-size: $font14;
                font-family: $fontFamilySourceHanSansCNMedium;
                font-weight: 500;
                color: $fontColor1E4;
              }
            }
          }
        }
      }
      .product-detail{
        display: flex;
        flex-direction: column;
        .menu-list{
          display: flex;
          flex-direction: column;
          margin-bottom: 39px;
          .menu-title{
            cursor: pointer;
            @include flex(row, space-between, center);
            height: 48px;
            background: $bgColorFAF;
            border: 1px solid #D3D3D3;
            padding: 0 16px;
            box-sizing: border-box;
            p{
              height: 20px;
              line-height: 20px;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
            }
            img{
              transition: all 0.3;
              transform: rotate(0);
              &.arrow-animate{
                transform: rotate(180deg);
                transition: all 0.3;
              }
            }
          }
          ul{
            height: 0;
            transition: all 0.3s;
            overflow: hidden;
            &.ul-animate{
              height: 134px;
              transition: height 0.3s;
            }
            li{
              margin-bottom: 31px;
              &:first-child{
                margin-top: 23px;
              }
              &:last-child{
                margin-bottom: 1px;
              }
              .insured-amount-box{
                @include flex(row, flex-start, center);
                p{
                  padding-left: 16px;
                  width: 230px;
                  height: 16px;
                  line-height: 16px;
                  font-size: $font16;
                  font-family: $fontFamilySourceHanSansCNRegular;
                  font-weight: 400;
                  color: $fontColor333;
                }
                .insured-amount{
                  @include flex(row, flex-start, center);
                  span{
                    margin-left: 8px;
                    width: 230px;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: $font16;
                    font-family: $fontFamilySourceHanSansCNRegular;
                    font-weight: 400;
                    color: $fontColor333;
                  }
                }
              }
            }

          }
        }
        .remark-title{
          margin-top: 9px;
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor9D9;
        }
        .remark-content{
          margin-top: 14px;
          line-height: 22px;
          font-size: $font14;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor9D9;
        }
      }
    }
  }
}
@keyframes turn{
  0%{-webkit-transform:rotate(0deg);}
  25%{-webkit-transform:rotate(90deg);}
  50%{-webkit-transform:rotate(180deg);}
  75%{-webkit-transform:rotate(270deg);}
  100%{-webkit-transform:rotate(360deg);}
}