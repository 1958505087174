@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.carousel-container{
    height: 700px;
    position: relative;
    .slider {
        .slider-item{
            cursor: pointer;
            &:first-child{
                position: relative;
            }
            .bubble{
                @include flex(column, flex-start, center);
                position: absolute;
                z-index: 11;
                width: 100px;
                height: 88px;
                background: $bgColorFD7;
                border-radius: 0px 0px 50px 50px;
                top: 0;
                right: 70px;
                font-family: $fontFamilySourceHanSansCNBold;
                // font-weight: bold;
                color: $fontColorFFF;
                line-height: 24px;
                font-size: $font16;
                letter-spacing: 1px;
                :first-child{
                    margin-top: 12px;
                    // letter-spacing: 1px;
                    font-size: $font14;
                }
            }
            .inner{
                @include flex(column, center, flex-start);
                padding-left: 64px;
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .title{
                    // margin-top: 146px;
                    height: 72px;
                    font-size: $font72;
                    font-family: $fontFamilySourceHanSansCNHeavy;
                    font-weight: 800;
                    color: $fontColorFFF;
                    line-height: 72px;
                    letter-spacing: 18px;
                    margin-bottom: 27px;
                }
                .desc-box{
                    // background-color: antiquewhite;
                    margin-top: 20px;
                    margin-left: 6px;
                    @include flex(row, flex-start, center);
                    img{
                        width: 22px;
                        height: 20px;
                    }
                    span{
                        margin: 0;
                        display: inline-block;
                        margin-left: 8px;
                        height: 25px;
                        font-size: $font18;
                        font-family: $fontFamilyPingFangRegular;
                        font-weight: 400;
                        color: $fontColorFFF;
                        line-height: 25px;
                        letter-spacing: 2px;
                    }
                }
                .more-box{
                    margin-left: 6px;
                    margin-top: 40px;
                    width: 166px;
                    height: 50px;
                    background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
                    border-radius: 6px;
                    @include flex(row, center, center);
                    span{
                        margin: 0;
                        display: inline-block;
                        height: 22px;
                        font-size: $font16;
                        font-family: $fontFamilyPingFangRegular;
                        font-weight: 400;
                        color: $fontColorFFF;
                        line-height: 22px;
                    }
                    img{
                        margin-left: 8px;
                        width: 14px;
                        height: 11px;
                    }
                }
            }
        }
        .banner{
            width: 100%;
            height: 700px;
            object-fit: cover;
        }
    }
    .page-dot{
        position: absolute;
        width: 100%;
        bottom: 86px;
        left: 0;
        @include flex(row, center, center);
        pointer-events: none;
        .dot-item{
            pointer-events: auto;
            cursor: pointer;
            margin-right: 8px;
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .page-control{
        position: absolute;
        top: 50%;
        width: 100%;
        height: 50px;
        transform: translateY(-50%);
        @include flex(row, space-between, center);
        padding: 0 48px;
        pointer-events: none;
        img{
            pointer-events: auto;
            cursor: pointer;
            // width: 22px;
            // height: 44px;
        }
    }
    .loading-box{
        @include flex(column, center, center);
        height: 700px;
    }
}