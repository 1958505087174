@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.compare-container{
  .banner-box{
    @include backgroundImg(100%, 100%, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_compare_insurance_banner.png', cover);
    @include flex(column, center, flex-start);
    height: 320px;
    padding-left: calc(100vw/2 - 624px);
    .title{
      height: 49px;
      line-height: 49px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColorFFF;
    }
    .desc{
      margin-top: 24px;
      width: 608px;
      line-height: 23px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
    }
  }
  .main{
    @include flex(column, flex-start, center);
    .select-compare{
      margin-top: 60px;
      margin-bottom: 60px;
      @include flex(column, flex-start, flex-start);
      width: 540px;
      background: $bgColorFFF;
      border: 1px solid #D3D3D3;
      .title{
        margin-left: -1px;
        width: 540px;
        height: 56px;
        background: $bgColor1E4;
        line-height: 56px;
        text-align: center;
        font-size: $font26;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColorFFF;
      }
      .form-container{
        @include flex(column, flex-start, center);
        padding: 24px 38px 0;
        width: 100%;
        box-sizing: border-box;
        .form-label{
          margin-bottom: 7px;
          text-align: left;
          width: 100%;
          height: 19px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
          line-height: 19px;
        }
      }
      .annuity-insurers{
        pointer-events: none;
        width: 100%;
        @include flex(column, flex-start, flex-start);
        padding: 0 38px;
        margin-bottom: 24px;
        height: 34px;
        .swiper-slide-item{
          background-color: $bgColor000;
          width: 80px;
          height: 34px;
        }
      }
    }
  }
}