@font-face {
  font-family: 'PingFang-Regular';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/PingFang-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PingFang-Semibold';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/PingFang-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-Regular';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-Medium';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-Heavy';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-Heavy.otf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-ExtraLight';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-ExtraLight.otf') format('truetype');
}
@font-face {
  font-family: 'SourceHanSansCN-Light';
  src: url('https://temp-cdn.huixinguanli.cn/static/media/font/SourceHanSansCN-Light.otf') format('truetype');
}