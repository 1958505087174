.slick-dots{
    margin-bottom: 86px;
    li {
        width: 12px !important;
        height: 12px !important;
        // margin-left: 10px !important;
        // margin-right: 19px !important;
        button {
            width: 12px !important;
            height: 12px !important;
            border-radius: 50% !important;
            border: 2px solid #447EDA !important;
            background-color: #fff !important;
        }
        &.slick-active{
            button{
                width: 12px !important;
                height: 12px !important;
                border-radius: 50% !important;
                background-color: #447EDA !important;
            }
        }
    }
}