@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.container{
  display: flex;
  flex-direction: column;
  width: 1248px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  // background-color: #EBEEF5;
  .main{
    display: flex;
    flex-direction: column;
    // padding: 40px 50px;
    padding: 40px 76px;
    box-sizing: border-box;
    // background-color: #fff;
    // box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
    .title{
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }
    i{
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    h3{
      margin-top: 20px;
      margin-bottom: 10px;
    }
    ul li{
      margin-left: 16px;
      // list-style-type: disc;
    }
    .content{
      display: flex;
      flex-direction: row;
      p{
        width: 10px;
        margin-right: 10px;
      }
      .desc{
        flex: 1;
      }
    }
  }
}