@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.consultant-container{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  .nav-bar{
    background-color: $bgColorF0F;
    .nav-back{
      width: 1248px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      padding-top: 17px;
      box-sizing: border-box;
      font-size: $font16;
      font-family: $fontFamilyPingFangRegular;
      font-weight: 400;
      color: $fontColor303;
      line-height: 22px;
      :first-child{
        cursor: pointer;
      }
      :last-child{
        color: $fontColor2C7;
      }
      span{
        height: 22px;
      }
      img{
        margin: 4px 8px 0;
        width: 18px;
        height: 18px;
      }
    }
  }
  .main{
    @include flex(column, flex-start, center);
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .title{
      margin-top: 50px;
      height: 35px;
      font-size: $font36;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
      line-height: 35px;
    }
    .form-container{
      margin-top: 59px;
      margin-bottom: 80px;
      width: 816px;
      background: $bgColorFAF;
      border: 1px solid #D3D3D3;
      padding: 48px 48px 49px;
      box-sizing: border-box;
      .input-label{
        @include flex(row, flex-start, center);
        margin-bottom: 4px;
        .label-box{
          @include flex(row, flex-start, center);
          width: 49.4%;
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor333;
          :last-child{
            color: $fontColorFF0;
          }
          &:last-child{
            margin-left: 1.3%;
            width: 49.3%;
          }
        }
      }
      .working-time{
        margin-top: 3px;
        p{
          margin-left: 50.7%;
          height: 12px;
          line-height: 12px;
          font-size: $font12;
          font-family: $fontFamilySourceHanSansCNExtraLight;
          font-weight: 200;
          color: $fontColor333;
        }
      }
      .needs-box{
        margin-top: 8px;
        margin-bottom: 4px;
        p{
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor333;
        }
      }
      .desc-title{
        margin-top: 23px;
        @include flex(row, flex-start, center);
        height: 12px;
        font-size: $font12;
        font-family: $fontFamilySourceHanSansCNExtraLight;
        font-weight: 200;
        color: $fontColor333;
        :first-child{
          color: $fontColorFF0;
        }
      }
      .desc-content{
        margin-top: 17px;
        line-height: 13px;
        font-size: $font12;
        font-family: $fontFamilySourceHanSansCNExtraLight;
        font-weight: 200;
        color: $fontColor333;
        .intro-title{
          color: $fontColor447;
        }
      }
      textarea{
        resize: none; 
        height: 120px;
        padding: 12px 16px;
        box-sizing: border-box;
      }
      input, textarea{
        font-size: 16px;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
        line-height: 16px;
        &::-webkit-input-placeholder{
          font-size: 16px;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor9D9;
          line-height: 16px;
        }
        &::-moz-placeholder{   /* Mozilla Firefox 19+ */
          font-size: 16px;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor9D9;
          line-height: 16px;
        }
      }
      input[type=number] {  
        -moz-appearance: textfield;  
      }  
      input[type=number]::-webkit-inner-spin-button,  
      input[type=number]::-webkit-outer-spin-button {  
          -webkit-appearance: none;  
          margin: 0;  
      } 
    }
  }
}