@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.insurance-policy{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  .main{
    @include flex(column, flex-start, flex-start);
    
    .title-box{
      @include flex(column, flex-start, center);
      width: 1248px;
      margin-left: auto;
      margin-right: auto;
      .title{
        width: 100%;
        display: inline-block;
        margin-top: 50px;
        height: 48px;
        line-height: 48px;
        font-size: $font50;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
      .desc{
        margin-top: 58px;
        width: 1010px;
        line-height: 40px;
        font-size: $font36;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
    }
    .upload-box{
      margin-top: 64px;
      width: 100%;
      @include flex(column, flex-start, center);
      background: $bgColorF5F;
      .title{
        margin-top: 58px;
        height: 35px;
        font-size: $font36;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor447;
        line-height: 35px;
      }
      .label-box{
        @include flex(row, flex-start, center);
        margin-bottom: 4px;
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
        :last-child{
          color: $fontColorFF0;
        }
      }
      .select-file{
        @include flex(column, center, center);
        width: 100%;
        height: 200px;
        .add-icon{
          width: 56px;
          height: 56px;
        }
        .add-desc{
          margin-top: 16px;
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor9D9;
        }
        .preview-img{
          text-align: center;
          max-width: 100%;
          max-height: 200px;
          object-fit: cover;
        }
      }
    }
    .upload-success{
      width: 100%;
      margin-top: 64px;
      @include flex(column, center, center);
      img{
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      p{
        margin-top: 29px;
        height: 35px;
        line-height: 35px;
        font-size: $font36;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor447;
      }
      .btn-group{
        @include flex(row, center, center);
        margin-top: 59px;
        margin-bottom: 60px;
      }
    }
  }
}
// .ant-modal-wrap{
//   background-color: aqua !important;
//   .ant-modal-body{
//     // padding-top: 40px !important;
//   }
// }