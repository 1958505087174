@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.program-made{
  // margin-top: 60px;
  position: relative;
  img {
    width: 100%;
    height: 460px;
    object-fit: cover;
  }
  .desc-box{
    @include flex(column, center, flex-start);
    position: absolute;
    z-index: 11;
    height: 100%;
    width: 394px;
    top: 0;
    left: calc(50vw - 623px);
    color: $fontColorFFF;
    :first-child{
      height: 57px;
      line-height: 57px;
      font-size: $font58;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      
    }
    :last-child{
      margin-top: 30px;
      height: 23px;
      line-height: 23px;
      font-size: $font24;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
    }
  }
  .made-box{
    position: absolute;
    z-index: 11;
    width: 394px;
    height: 100%;
    background: $bgColorFFF;
    top: 0;
    bottom: 0;
    right: calc(50vw - 623px);
    padding: 31px 35px 0;
    box-sizing: border-box;
    .made-desc{
      margin-bottom: 24px;
      height: 25px;
      font-size: $font26;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
      line-height: 25px;
    }
    .send-btn{
      cursor: pointer;
      height: 16px;
      font-size: $font16;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: #1E4994;
      line-height: 16px;
      margin-left: 13px;
    }
    .receive-box{
      @include flex(row, center, center);
      margin-top: 36px;
      button{
        padding: 0;
        width: 120px;
        height: 46px;
        background-color: $bgColorF2A;
        text-align: center;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColorFFF;
        line-height: 46px;
        border-color: transparent;
      }
    }
    input{
      font-size: 16px;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      &::-webkit-input-placeholder{
        // height: 16px;
        // line-height: 16px;
        color: $fontColor9D9;
        
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        // height: 16px;
        color: $fontColor9D9;
        // line-height: 16px;
      }
    }
    input[type=number] {  
      -moz-appearance:textfield;  
    }  
    input[type=number]::-webkit-inner-spin-button,  
    input[type=number]::-webkit-outer-spin-button {  
        -webkit-appearance: none;  
        margin: 0;  
    } 
  }
}