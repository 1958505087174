@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.footer{
  @include flex(column, flex-start, center);
  background: $bgColor447;
  padding-top: 60px;
  padding-bottom: 40px;
  box-sizing: border-box;
  width: 100%;
  .main{
    @include flex(column, flex-start, flex-start);
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .top-box{
      width: 100%;
      @include flex(row, space-between, flex-start);
      .company-profile{
        @include flex(column, flex-start, flex-start);
        .detail-box{
          @include flex(row, flex-start, center);
          margin-top: 19px;
          &:first-child{
            margin-top: 0;
          }
          img{
            object-fit: cover
          }
          p{
            margin-left: 8px;
            height: 14px;
            line-height: 14px;
            font-size: $font14;
            font-family: $fontFamilySourceHanSansCNLight;
            font-weight: 300;
            color: $fontColorFFF;
          }
          .company-name{
            margin-left: 0px;
            height: 16px;
            line-height: 16px;
            font-size: $font16;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColorFFF;
          }
        }
      }
      .contact-us{
        @include flex(column, flex-start, flex-start);
        .contact-title{
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColorFFF;
        }
        .share-box{
          margin-top: 24px;
          @include flex(row, flex-start, center);
          a{
            margin-right: 55px;
          }
          :last-child{
            margin-right: 0px;
          }
        }
      }
    }
    .bottom-box{
      width: 100%;
      margin-top: 59px;
      padding: 8px 0;
      box-sizing: border-box;
      border-top: 2px solid rgba(255, 255, 255, 0.12);
      @include flex(row, space-between ,flex-start);
      :first-child{
        line-height: 13px;
        font-size: $font12;
        font-family: $fontFamilySourceHanSansCNExtraLight;
        font-weight: 200;
        color: $fontColorFFF;
      }
      :last-child{
        &:hover{
          text-decoration: underline;
        }
        height: 14px;
        line-height: 14px;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNLight;
        font-weight: 300;
        color: $fontColorFFF;
      }
    }
  }
}