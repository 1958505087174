body,div,p,span,h1,h2,h3,h4,h5,h6,ul,li,dl,dt,a,input,button,textarea,select{
  margin: 0;
  padding: 0;
  outline: none;
}
a{
  text-decoration: none;
}
ul,li{
  list-style: none;
}
input{
  font: normal;
}
input:focus, a:focus{
  outline: none;
}
/* html, body{
  scroll-behavior: smooth;
} */