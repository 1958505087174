@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.activity-container{
  display: flex;
  flex-direction: column;
  background-color: $bgColorFFF;
  // margin-top: 122px;
  .nav-back{
    background-color: $bgColorF0F;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding: 17px 76px 0;
    box-sizing: border-box;
    font-size: $font16;
    font-family: $fontFamilyPingFangRegular;
    font-weight: 400;
    color: $fontColor303;
    line-height: 22px;
    :first-child{
      cursor: pointer;
    }
    :last-child{
      color: $fontColor2C7;
    }
    span{
      height: 22px;
    }
    img{
      margin: 4px 8px 0;
      width: 18px;
      height: 18px;
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    background-color: $bgColorFFF;
    // background-color: antiquewhite;
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .filter-box{
      display: flex;
      flex-direction: column;
      margin: 0 70px;
      padding: 50px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #D4D4E1;
      .filter-title{
        height: 45px;
        font-size: $font32;
        font-family: $fontFamilyPingFangSemibold;
        font-weight: 600;
        color: $fontColor303;
        line-height: 45px;
        letter-spacing: 2px;
        margin-left: 6px;
      }
      .filter-item-box{
        margin-left: 6px;
        margin-top: 32px;
        @include flex(row, flex-start, flex-start);
        .filter-type{
          width: 61px;
          height: 25px;
          font-size: $font18;
          font-family: $fontFamilyPingFangRegular;
          font-weight: 400;
          color: $fontColor303;
          line-height: 25px;
          margin-right: 30px;
        }
        .filter-item{
          flex: 1;
          @include flex(row, flex-start, center);
          flex-wrap: wrap;
          span{
            cursor: pointer;
            margin-right: 32px;
            height: 25px;
            font-size: $font18;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor303;
            line-height: 25px;
          }
          .filter-item-active{
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor2C7;
          }
        }
      }
    }
    .activity-list{
      display: flex;
      flex-direction: column;
      padding: 10px 70px 40px;
      box-sizing: border-box;
      .activity-item{
        cursor: pointer;
        @include flex(row, flex-start, flex-start);
        padding: 40px 0;
        img{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        p{
          @include ellipsis();
          height: 25px;
          font-size: $font16;
          font-family: $fontFamilyPingFangRegular;
          font-weight: 400;
          color: $fontColor303;
          line-height: 25px;
        }
        .activity-cover{
          width: 348px;
          height: 260px;
          margin-right: 32px;
          object-fit: cover;
        }
        .activity-info{
          flex: 1;
          display: flex;
          flex-direction: column;
          .activity-title-box{
            @include flex(row, flex-start, flex-start);
            .activity-title{
              // flex: 1;
              @include ellipsis();
              height: 45px;
              font-size: $font32;
              font-family: $fontFamilyPingFangSemibold;
              font-weight: 600;
              color: $fontColor303;
              line-height: 45px;
              letter-spacing: 2px;
              margin-right: 6px;
            }
            .gift-box{
              width: 66px;
              height: 24px;
              background: $bgColorFD7;
              border-radius: 12px 12px 12px 0px;
              text-align: center;
              font-size: $font14;
              font-family: $fontFamilyPingFangRegular;
              font-weight: 400;
              color: $fontColorFFF;
              line-height: 24px;
            }
          }
          .activity-date{
            @include flex(row, flex-start, center);
            margin-top: 16px;
          }
          .activity-address{
            @include flex(row, flex-start, center);
            margin-top: 16px;
          }
          .activity-quota{
            @include flex(row, flex-start, center);
            margin-top: 16px;
            .highlight-quota{
              font-family: $fontFamilyPingFangSemibold;
              font-weight: 600;
              color: $fontColorFD7;
            }
            .activity-cost{
              @include flex(row, flex-start, center);
            }
          }
          .activity-end{
            margin-top: 32px;
            width: 134px;
            height: 42px;
            background: $bgColorB7B;
            border-radius: 6px;
            text-align: center;
            p{
              height: 42px;
              font-size: $font16;
              font-family: $fontFamilyPingFangRegular;
              font-weight: 400;
              color: $fontColorFFF;
              line-height: 42px;
            }
          }
          .activity-apply{
            @include flex(row, center, center);
            cursor: pointer;
            margin-top: 32px;
            width: 134px;
            height: 42px;
            background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
            border-radius: 6px;
            p{
              height: 22px;
              font-size: $font16;
              font-family: $fontFamilyPingFangRegular;
              font-weight: 400;
              color: $fontColorFFF;
              line-height: 22px;
            }
            img{
              margin-left: 8px;
              width: 14px;
              height: 11px;
            }
          }
        }
      }
    }
    .loading-box{
      @include flex(column, center, center);
      height: 75vh;
      position: static;
    }
  }
}