@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.about-container{
  display: flex;
  flex-direction: column;
  .nav-bar{
    background-color: $bgColorF0F;
    .nav-back{
      width: 1248px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      padding-top: 17px;
      box-sizing: border-box;
      font-size: $font16;
      font-family: $fontFamilyPingFangRegular;
      font-weight: 400;
      color: $fontColor303;
      line-height: 22px;
      :first-child{
        cursor: pointer;
      }
      :last-child{
        color: $fontColor2C7;
      }
      span{
        height: 22px;
      }
      img{
        margin: 4px 8px 0;
        width: 18px;
        height: 18px;
      }
    }
  }
  .main{
    .header{
      margin-top: 60px;
      @include flex(column, flex-start, flex-start);
      :first-child{
        width: 100%;
        text-align: center
      }
      :last-child{
        margin: 32px auto 60px;
        width: 1108px;
        font-size: $font16;
        font-family: $fontFamilyPingFangRegular;
        font-weight: 400;
        color: $fontColor636;
        line-height: 25px;
      }
    }
    .company-profile{
      @include flex(column, flex-start, center);
      padding: 80px 0px 12px;
      box-sizing: border-box;
      background-color: $bgColorFFF;
      img{
        margin-top: 56px;
        width: 1108px;
        height: 442px;
      }
      .profile-list{
        display: flex;
        flex-direction: column;
        padding: 20px 0 60px;
        box-sizing: border-box;
        .profile-item{
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          box-sizing: border-box;
          span{
            height: 45px;
            font-size: $font32;
            font-family: $fontFamilyPingFangSemibold;
            color: $fontColor303;
            line-height: 45px;
            letter-spacing: 2px;
          }
          p{
            margin-top: 32px;
            width: 1108px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
    .company-achievement{
      @include flex(row, center, flex-start);
      box-sizing: border-box;
      background-color: $bgColorF0F;
      padding:  80px 0;
      .left-box{
        @include flex(row, flex-start, flex-start);
        position: relative;
        .bg-box{
          margin-top: 6px;
          width: 300px;
          height: 300px;
          border-radius: 50%;
          background: url('https://temp-cdn.huixinguanli.cn/static/media/images/icon_hz14_bg.png') no-repeat center;
        }
        .year-box{
          @include flex(column, flex-start, center);
          margin-top: 26px;
          width: 320px;
          height: 310px;
          border-radius: 50%;
          background: $bgColorFFF;
          margin-left: -230px;
          margin-right: 80px;
          box-shadow: 8px 10px 40px 0px rgba(0, 64, 133, 0.4);
          .total-time{
            margin-top: 12px;
            width: 150px;
            height: 207px;
            font-size: 148px;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor2C7;
            line-height: 207px;
          }
          .focus-duties{
            margin-top: -33px;
            text-align: center;
            font-size: $font24;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor303;
            line-height: 33px;
            letter-spacing: 2px;
          }
        }
        .bule-ball{
          position: absolute;
          top: 6px;
          right: 10px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
        }
        .yellow-ball{
          position: absolute;
          top: 105px;
          right: 0px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: linear-gradient(270deg, #FFB52D 0%, #FD7E14 100%);
        }
      }
      .right-box{
        margin-left: 100px;
        @include flex(column, flex-start, flex-start);
        .right-item{
          @include flex(column, flex-start, flex-start);
          margin-top: 40px;
          font-family: $fontFamilyPingFangSemibold;
          font-weight: 600;
          color: $fontColor2C7;
          &:first-child{
            margin-top: 0px;
          }
          strong{
            height: 56px;
            font-size: $font40;
            line-height: 56px;
            font{
              margin-left: 7px;
              height: 33px;
              font-size: $font24;
              line-height: 33px;
            }
          }
          p{
            height: 33px;
            font-size: $font24;
            color: $fontColor303;
            line-height: 33px;
            letter-spacing: 2px;
          }
        }
      }
    }
    .teams-box{
      @include flex(column, flex-start, center);
      padding: 80px 85px;
      box-sizing: border-box;
      background-color: $bgColorF0F;
      .teams-list{
        margin-top: 54px;
        @include flex(row, center, center);
        .teams-item{
          @include flex(column, flex-start, center);
          background: #FFFFFF;
          border-radius: 10px;
          overflow: hidden;
          margin-right: 30px;
          &:last-child{
            margin-right: 0px;
          }
          .member-cover{
            width: 255px;
            height: 264px;
          }
          .member-name{
            margin-top: 10px;
            text-align: center;
            height: 33px;
            font-size: $font24;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor303;
            line-height: 33px;
            letter-spacing: 2px;
          }
          .member-position{
            margin-bottom: 16px;
            text-align: center;
            height: 25px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
    .subtitle{
      text-align: center;
      // margin-top: 6px;
      height: 56px;
      font-size: $font40;
      font-family: $fontFamilyPingFangSemibold;
      font-weight: 600;
      color: $fontColor303;
      line-height: 56px;
      letter-spacing: 3px;
    }
  }
}