.coming-soon-container{
  min-width: 1736px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .coming-soon-banner1{
    width: 100%;
    height: 1292px;
    object-fit: cover;
  }
  .coming-soon-banner2{
    position: absolute;
    z-index: 10;
    right: 0px;
    top: 656px;
    width: 1266px;
    height: 808px;
  }
  .banner-container{
    margin-top: -54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .coming-soon-banner3{
      width: 1300px;
      height: 732px;
      margin-right: 435px;
    }
  }
  
}
