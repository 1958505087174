@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.container{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  width: 1248px;
  margin-left: auto;
  margin-right: auto;
  .nav-back{
    background-color: $bgColorF0F;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding: 17px 76px 0;
    box-sizing: border-box;
    font-size: $font16;
    font-family: $fontFamilyPingFangRegular;
    font-weight: 400;
    color: $fontColor303;
    line-height: 22px;
    :first-child{
      cursor: pointer;
    }
    :last-child{
      color: $fontColor2C7;
    }
    span{
      height: 22px;
    }
    img{
      margin: 4px 8px 0;
      width: 18px;
      height: 18px;
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    padding: 40px 76px;
    h1{
      text-align: center;
    }
    h3{
      margin-top: 20px;
      margin-bottom: 10px;
    }
    ul li{
      margin-left: 40px;
      list-style-type: disc;
    }
  }
}