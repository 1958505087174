@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.container{
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  .main{
    display: flex;
    flex-direction: column;
    background-color: $bgColorFFF;
    .nav-back{
      background-color: $bgColorF0F;
      height: 60px;
      display: flex;
      flex-direction: row;
      padding: 17px 76px 0;
      box-sizing: border-box;
      font-size: $font16;
      font-family: $fontFamilyPingFangRegular;
      font-weight: 400;
      color: $fontColor303;
      line-height: 22px;
      :first-child{
        cursor: pointer;
      }
      :last-child{
        color: $fontColor2C7;
      }
      span{
        height: 22px;
      }
      img{
        // transform: rotate(-90deg);
        // -ms-transform: rotate(-90deg);
        // /* Internet Explorer 9*/
        // -moz-transform: rotate(-90deg);
        // /* Firefox */
        // -webkit-transform: rotate(-90deg);
        // /* Safari 和 Chrome */
        // -o-transform: rotate(-90deg);
        margin: 4px 8px 0;
        width: 18px;
        height: 18px;
      }
    }
    .title-box{
      @include flex(column, center, center);
      margin-top: 80px;
      .title{
        height: 22px;
        font-size: $font16;
        font-family: $fontFamilyPingFangRegular;
        font-weight: 600;
        color: $fontColorFD7;
        line-height: 22px;
      }
      .subtitle{
        margin-top: 6px;
        height: 56px;
        font-size: $font40;
        font-family: $fontFamilyPingFangSemibold;
        font-weight: 600;
        color: $fontColor303;
        line-height: 56px;
        letter-spacing: 3px;
      }
    }
    .category-box{
      @include flex(row, center, center);
      margin-top: 56px;
      font-size: $font18;
      font-family: $fontFamilyPingFangRegular;
      font-weight: normal;
      color: $fontColor303;
      line-height: 25px;
      span{
        height: 25px;
        cursor: pointer;
        margin-right: 32px;
      }
      :last-child{
        margin-right: 0px;
      }
      .category-active{
        font-family: $fontFamilyPingFangSemibold;
        color: $fontColor2C7;
      }
    }
    .product-list{
      @include flex(column, flex-start, center);
      margin-top: 16px;
      margin-bottom: 40px;
      .product-item{
        @include flex(row, center, flex-start);
        padding: 40px 0;
        box-sizing: border-box;
        img{
          width: 288px;
          height: 288px;
          margin-right: 25px;
        }
        .product-desc{
          @include flex(column, flex-start, flex-start);
          span{
            margin-top: 20px;
            height: 45px;
            font-size: $font32;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: normal;
            color: $fontColor303;
            line-height: 45px;
            letter-spacing: 2px;
          }
          p{
            margin-top: 16px;
            width: 795px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
  }
}